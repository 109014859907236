<template>
  <div class="flex h-screen bg-gray-900 text-white">
    <!-- Sidebar -->
    <div class="sidebar w-1/5 bg-gray-800 p-6 flex flex-col">
      <div class="title font-semibold text-xl mb-8">Sociétés</div>
      <div class="mb-4">
        <button 
          @click="navigateTo('dashboard')" 
          :class="{'bg-gray-600': isActive('dashboard')}" 
          class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2"
        >
          <span class="material-icons mr-4">dashboard</span>
          Dashboard
        </button>
        <button 
          @click="navigateTo('companies')" 
          :class="{'bg-gray-600': isActive('companies')}" 
          class="w-full flex items-center px-4 py-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition duration-300 mb-2"
        >
          <span class="material-icons mr-4">business</span>
          Sociétés
        </button>
      </div>
    </div>
    <!-- Content Area -->
    <div class="flex-1 flex flex-col p-8">
      <h2 class="text-3xl font-bold mb-6">Liste des Sociétés</h2>
      <button @click="showCreateCompanyForm = true" class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mb-6">
        Ajouter une Société
      </button>

      <!-- Modal pour Créer une Nouvelle Société -->
      <div v-if="showCreateCompanyForm" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
          <h3 class="text-xl font-semibold mb-4">Ajouter une Société</h3>
          <form @submit.prevent="handleCreateCompany">
            <div class="form-group mb-4">
              <label for="name" class="block text-sm font-semibold mb-2">Nom de la société</label>
              <input v-model="newCompany.name" type="text" id="name" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
            </div>
            <div class="form-group mb-4">
              <label for="type" class="block text-sm font-semibold mb-2">Type de société</label>
              <select v-model="newCompany.type" id="type" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white">
                <option value="Masonry">Entreprise de maçonnerie</option>
                <option value="Architect">Architecte</option>
                <option value="Owner">Maître d'ouvrage</option>
                <option value="Engineering">Bureau d'ingénieur</option>
              </select>
            </div>
            <div class="form-group mb-4">
              <label for="address" class="block text-sm font-semibold mb-2">Adresse postale</label>
              <input 
                v-model="newCompany.address" 
                @input="fetchAddressSuggestions" 
                type="text" 
                id="address" 
                placeholder="Choisir une adresse" 
                class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white"
              />
              <ul v-if="addressSuggestions.length" class="bg-gray-700 border border-gray-600 rounded-lg mt-2">
                <li 
                  v-for="(suggestion, index) in addressSuggestions" 
                  :key="index" 
                  @click="selectAddress(suggestion)"
                  class="cursor-pointer px-4 py-2 hover:bg-gray-600"
                >
                  {{ suggestion }}
                </li>
              </ul>
            </div>
            <div class="form-group mb-4">
              <label for="email" class="block text-sm font-semibold mb-2">Email</label>
              <input v-model="newCompany.email" type="email" id="email" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
            </div>
            <div class="form-group mb-4">
              <label for="phone" class="block text-sm font-semibold mb-2">Numéro de téléphone</label>
              <input v-model="newCompany.phone" type="tel" id="phone" required class="w-full px-4 py-2 rounded-lg bg-gray-700 border border-gray-600 focus:border-blue-500 focus:outline-none text-white" />
            </div>
            <div class="flex justify-end mt-4">
              <button type="button" @click="showCreateCompanyForm = false" class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2">Annuler</button>
              <button type="submit" class="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">Ajouter</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Liste des Sociétés -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
        <div v-for="company in companies" :key="company.id" class="bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 class="text-xl font-semibold">{{ company.name }}</h3>
          <p class="text-gray-400">Type : {{ company.type }}</p>
          <p class="text-gray-400">Adresse : {{ company.address }}</p>
          <p class="text-gray-400">Email : {{ company.email }}</p>
          <p class="text-gray-400">Téléphone : {{ company.phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '../supabase';
import axios from 'axios';
import debounce from 'lodash/debounce';

export default {
  name: 'CompanyList',
  data() {
    return {
      companies: [],
      newCompany: {
        name: '',
        type: 'Masonry',
        address: '',
        email: '',
        phone: ''
      },
      showCreateCompanyForm: false,
      addressSuggestions: []
    };
  },
  methods: {
    navigateTo(page) {
      this.$router.push({ name: page });
    },
    isActive(page) {
      return this.$route.name === page;
    },
    fetchAddressSuggestions: debounce(async function() {
      if (!this.newCompany.address.trim()) {
        this.addressSuggestions = [];
        return;
      }

      try {
        const response = await axios.get(`${window.location.origin}/api/places`, {
          params: {
            input: this.newCompany.address
          },
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });

        console.log('Raw API Response:', response);

        if (response.data && response.data.features) {
          this.addressSuggestions = response.data.features.map(feature => 
            feature.place_name_fr || feature.place_name
          );
        } else {
          console.warn('Unexpected response format:', response.data);
          this.addressSuggestions = [];
        }
      } catch (error) {
        console.error('Address suggestion error:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          config: error.config
        });
        this.addressSuggestions = [];
      }
    }, 300),

    selectAddress(suggestion) {
      this.newCompany.address = suggestion;
      this.addressSuggestions = [];
    },

    async handleCreateCompany() {
      try {
        const { data, error } = await supabase.from('companies').insert([this.newCompany]).select();
        
        if (error) {
          console.error('Erreur lors de la création de la société :', error.message);
          return;
        }
        
        if (data && data.length > 0) {
          this.companies.push(data[0]);
          this.showCreateCompanyForm = false;
          this.newCompany = { name: '', type: 'Masonry', address: '', email: '', phone: '' };
        } else {
          console.error('No data returned after company creation');
        }
      } catch (error) {
        console.error('Erreur lors de la création de la société :', error.message);
      }
    },
  },
};
</script>

<style scoped>

@import '@vueform/multiselect/themes/default.css'; 
@import url('https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;600&display=swap');

body {
  font-family: 'Segoe UI', sans-serif;
  background-color: #121212;
}

.sidebar {
  max-width: 300px;
}

button {
  display: flex;
  align-items: center;
}

input,
ul {
  background-color: #374151;
  border: 1px solid #4b5563;
  color: #ffffff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

input:focus {
  border-color: #4299e1;
}

ul {
  margin-top: 0.5rem;
}

li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

li:hover {
  background-color: #2b6cb0;
}
</style>
